import { breakpoints, orderedAliases } from '@constants/breakpoints';

/** Converts a Record<keyof breakpoints, number> (where 1 is all 12 columns, i.e. full width)
 * to a string that img.sizes can use to drive the srcset param
 * e.g. (min-width: ${breakpoints.xxl}) 25vw, (min-width: ${breakpoints.sm}) calc(100vw / 3), 50vw
 *
 * Note that the smallest breakpoint is used to specify the default width
 */
export function sizesStringFromCardWidths(cardWidths) {
  return orderedAliases
    .reduce((sizes, breakpoint) => {
      const size = cardWidths[breakpoint];
      if (!size) {
        return sizes;
      }

      const query = sizes.length ? `(min-width: ${breakpoints[breakpoint]}) ` : '';

      sizes.push(`${query}${size * 100}vw`);
      return sizes;
    }, [])
    .reverse()
    .join(', ');
}
